import React from 'react'
import Sidebar from '../App/Components/Sidebar'
import ProductDetail from '../App/Components/ProductDetail'

function DetailPage() {
  return (
    <>
        <Sidebar/>
        <ProductDetail/>
    </>
  )
}

export default DetailPage