import React from 'react'
import Sidebar from './Sidebar'
import OpenSetting from './OpenSetting'
import ResetPassword from './ResetPassword'
export default function Setting() {
    return (
        <div>
            <Sidebar />
            <main className="main-content position-relative max-height-vh-100 h-100 mt-1 border-radius-lg ">
                <nav className="navbar navbar-main navbar-expand-lg px-0 mx-4 shadow-none border-radius-xl" id="navbarBlur" navbar-scroll="true">
                    <div className="container-fluid py-1 px-3">
                        <nav aria-label="breadcrumb">
                            <ol className="breadcrumb bg-transparent mb-0 pb-0 pt-1 px-0 me-sm-6 me-5">
                                <li className="breadcrumb-item text-sm"><a className="opacity-5 text-dark" href="#">Setting</a></li>
                                <li className="breadcrumb-item text-sm text-dark active" aria-current="page">System & Password Setting</li>
                            </ol>

                        </nav>
                        <div className="collapse navbar-collapse mt-sm-0 mt-2 me-md-0 me-sm-4" id="navbar">
                            <div className="ms-md-auto pe-md-3 d-flex align-items-center">
                                <div className="input-group">
                                    <span className="input-group-text text-body"><i className="fas fa-search" aria-hidden="true"></i></span>
                                    <input type="text" className="form-control" placeholder="Type here..." />
                                </div>
                            </div>

                        </div>
                    </div>
                </nav>
                <div className="container-fluid py-4">
                    <div className="row">
                        <div className="col-12">
                            <div className="card mb-4">
                                <div className="card-header pb-0">
                                    <h6>Setting</h6>
                                </div>
                                <OpenSetting />
                                <ResetPassword />

                            </div>
                        </div>
                    </div>
                </div>
            </main>
        </div>
    )
}
