import React, { useState } from "react";
import axios from "axios";
import toast from "react-hot-toast";
import { useNavigate } from "react-router-dom";
import Helpers from "./Helpers";

function Login() {
  const [data, setData] = useState({
    email: "",
    password: "",
  });
  const [isLoading, setIsLoading] = useState(false);
  const navigate = useNavigate();

  const handleChange = ({ target: { name, value } }) => {
    setData({ ...data, [name]: value });
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    setIsLoading(true);
    try {
      console.log(Helpers.apiUrl);
      const response = await axios.post(`${Helpers.apiUrl}login`, data);
      localStorage.setItem("user", JSON.stringify(response.data.user));
      localStorage.setItem("token", response.data.access_token);
      navigate("/dashboard");
      console.log("Login successful");
      setIsLoading(false);
    } catch (error) {
      setIsLoading(false);
      //console.error("Login failed:", error.response.data);
      toast.error(error.message);
    }
  };

  return (
    <>
      {/* <form onSubmit={handleSubmit}>
            <input type="email" name="email" value={data.email} onChange={handleChange} placeholder="Email" />
            <input type="password" name="password" value={data.password} onChange={handleChange} placeholder="Password" />
            <button type="submit">Login</button>
        </form> */}
      <div className="row">
        <div className="col-xl-4 col-lg-5 col-md-6 d-flex flex-column mx-auto">
          <div className="card card-plain mt-8">
            <div className="card-header pb-0 text-left bg-transparent">
              <h3 className="font-weight-bolder text-info text-gradient">
                Login to your Account
              </h3>
              <p className="mb-0">Enter your email and password to sign in</p>
            </div>
            <div className="card-body">
              <form role="form" onSubmit={handleSubmit}>
                <label>Email</label>
                <div className="mb-3">
                  <input
                    type="email"
                    className="form-control"
                    placeholder="Email"
                    aria-label="Email"
                    aria-describedby="email-addon"
                    name="email"
                    value={data.email}
                    onChange={handleChange}
                  />
                </div>
                <label>Password</label>
                <div className="mb-3">
                  <input
                    type="password"
                    className="form-control"
                    placeholder="Password"
                    aria-label="Password"
                    aria-describedby="password-addon"
                    name="password"
                    value={data.password}
                    onChange={handleChange}
                  />
                </div>
                <div className="form-check form-switch">
                  <input
                    className="form-check-input"
                    type="checkbox"
                    id="rememberMe"
                  />
                  <label className="form-check-label" htmlFor="rememberMe">
                    Remember me
                  </label>
                </div>
                <div className="text-center">
                  <button
                    type="submit"
                    className="btn bg-gradient-info w-100 mt-4 mb-0"
                  >
                    {isLoading ? "Please wait .." : "Login"}
                  </button>
                </div>
              </form>
            </div>
            {/* <div className="card-footer text-center pt-0 px-lg-2 px-1">
        <p className="mb-4 text-sm mx-auto">
          Don't have an account?
          <a href="#sign-up" className="text-info text-gradient font-weight-bold">Sign up</a>
        </p>
      </div> */}
          </div>
        </div>
      </div>
    </>
  );
}

export default Login;
