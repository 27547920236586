import React, { useState } from 'react'
import { Routes, Route, Link } from 'react-router-dom';
import Home from './Home';
import Dashboard from './Dashboard';
import { NavLink } from 'react-router-dom';
import { useNavigate } from 'react-router-dom';
function Sidebar() {
  const navigate = useNavigate();

  const handleLogout = () => {
    localStorage.removeItem('user');
    localStorage.removeItem('token');

    navigate('/login');
    console.log('Logged out successfully');
  };
  return (
    <>
      <aside className="sidenav navbar navbar-vertical navbar-expand-xs border-0 border-radius-xl my-3 fixed-start ms-3 " id="sidenav-main">
        <div className="sidenav-header">
          <i className="fas fa-times p-3 cursor-pointer text-secondary opacity-5 position-absolute end-0 top-0 d-none d-xl-none" aria-hidden="true" id="iconSidenav"></i>
          <a className="navbar-brand m-0" href="#" target="_blank">
            <img src="../assets/img/logo-ct.png" className="navbar-brand-img h-100" alt="main_logo" />
            <span className="ms-1 font-weight-bold">Excel Sheet</span>
          </a>
        </div>
        <div className="collapse navbar-collapse  w-auto  max-height-vh-100 h-100" id="sidenav-collapse-main">
          <ul className="navbar-nav">
            <li className="nav-item">
              <NavLink
                to="/dashboard"
                className={({ isActive }) => "nav-link" + (isActive ? " active" : "")}
              >
                <div className="icon icon-shape icon-sm shadow border-radius-md bg-white text-center me-2 d-flex align-items-center justify-content-center">
                  <i className="fa-solid fa-house" style={{ color: "black" }}></i>
                  {/* <svg width="12px" height="12px" viewBox="0 0 45 40" version="1.1" xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink">
                    <title>shop </title>
                  
                  </svg> */}
                </div>
                <span className="nav-link-text ms-1">Dashboard</span>
              </NavLink>
            </li>
            <li className="nav-item">
              <NavLink
                to="/link"
                className={({ isActive }) => "nav-link" + (isActive ? " active" : "")}
              >
                <div className="icon icon-shape icon-sm shadow border-radius-md bg-white text-center me-2 d-flex align-items-center justify-content-center">
                  <i className="fa-solid fa-list" style={{ color: "black" }} ></i>
                </div>
                <span className="nav-link-text ms-1">List</span>
              </NavLink>
            </li>
            <li className="nav-item">
              <NavLink
                to="/setting"
                className={({ isActive }) => "nav-link" + (isActive ? " active" : "")}
              >
                <div className="icon icon-shape icon-sm shadow border-radius-md bg-white text-center me-2 d-flex align-items-center justify-content-center">
                  <i className="fa-solid fa-cog" style={{ color: "black" }} ></i>
                </div>
                <span className="nav-link-text ms-1">Setting</span>
              </NavLink>
            </li>

          </ul>
          <button class="btn bg-gradient-primary w-100" style={{ marginTop: "28rem" }} href="#" onClick={handleLogout}>Logout</button>
        </div>
        <div class=" ">

        </div>
      </aside>
    </>
  )
}

export default Sidebar