import React, { useEffect, useState } from 'react';
import axios from 'axios';
import Sidebar from './Sidebar'
import toast from 'react-hot-toast';
import { useNavigate } from 'react-router-dom';
import Helpers from './Helpers';
function Home() {
    const [products, setProducts] = useState([]);
    const [headers, setHeaders] = useState([]);
    const [currentPage, setCurrentPage] = useState(1)
    const [totalPages, setTotalPages] = useState(0);
    const [categories, setCategory] = useState([]);
    const navigate = useNavigate()
    const handleCurrentPage = () => {
        console.log("current page", currentPage)
        setCurrentPage(currentPage + 1)
    }

    useEffect(() => {
        axios.get(`${Helpers.apiUrl}productlist?page=${currentPage}`)
            .then(response => {
                console.log("response", response.data)
                console.log("Categories will be here ", response.data.data.map(item => item.category_name));

                // toast.success('Success');
                // Flatten the array of arrays to a single array of objects
                const flattenedData = response.data?.data.flat();

                setProducts(flattenedData);
                setTotalPages(response.data.meta.last_page);
                setCategory(response.data.categories);

                if (flattenedData.length > 0) {
                    // Dynamically set headers based on the keys of the first object
                    setHeaders(Object.keys(flattenedData[0]));
                }
            })
            .catch(error => {
                // toast.error(errror);

                console.error('There was an error fetching the products data:', error);
            });

    }, [currentPage]);
    const handleViewDetail = (productId) => {
        // window.location.href = `/path-to-detail-page/${productId}`;
        navigate(`/path-to-detail-page/${productId}`)
    };
    const handlePreviousPage = () => {
        if (currentPage > 1) {
            setCurrentPage(currentPage - 1);
        }
    };

    const handleNextPage = () => {
        if (currentPage < totalPages) {
            setCurrentPage(currentPage + 1);
        }
    };


    return (
        <>
            <Sidebar />

            <main className="main-content position-relative max-height-vh-100 h-100 mt-1 border-radius-lg ">
                <nav className="navbar navbar-main navbar-expand-lg px-0 mx-4 shadow-none border-radius-xl" id="navbarBlur" navbar-scroll="true">
                    <div className="container-fluid py-1 px-3">
                        <nav aria-label="breadcrumb">
                            <ol className="breadcrumb bg-transparent mb-0 pb-0 pt-1 px-0 me-sm-6 me-5">
                                <li className="breadcrumb-item text-sm"><a className="opacity-5 text-dark" href="javascript:;">List of Data</a></li>
                                <li className="breadcrumb-item text-sm text-dark active" aria-current="page">Tables</li>
                            </ol>
                            <h6 className="font-weight-bolder mb-0">Tables</h6>
                        </nav>
                        <div className="collapse navbar-collapse mt-sm-0 mt-2 me-md-0 me-sm-4" id="navbar">
                            <div className="ms-md-auto pe-md-3 d-flex align-items-center">
                                <div className="input-group">
                                    <span className="input-group-text text-body"><i className="fas fa-search" aria-hidden="true"></i></span>
                                    <input type="text" className="form-control" placeholder="Type here..." />
                                </div>
                            </div>
                            <ul className="navbar-nav  justify-content-end">

                            </ul>
                        </div>
                    </div>
                </nav>
                <div className="container-fluid py-4">
                    <div className="row">
                        <div className="col-12">
                            <div className="card mb-4">
                                <div className="card-header pb-0">
                                    <h6>Data table</h6>
                                    {/* <label htmlFor="">Select A Category</label>
                                    <select name="" id="">
                                        <option value="">Select a category</option>
                                        {categories.map(category => (
                                            <option key={category.id} value={category.id}>{category.name}</option>
                                        ))}
                                    </select> */}
                                </div>
                                <div className="card-body px-0 pt-0 pb-2">
                                    <div className="table-responsive p-0">
                                        <table className="table  mb-0">
                                            <thead>
                                                <tr>
                                                    {/* {headers.map(header => (
                                                        <th key={header} className="text-uppercase text-secondary text-xxs font-weight-bolder opacity-7">
                                                            {header.replace(/_/g, ' ')} 
                                                        </th>
                                                    ))} */}
                                                    <th className="text-uppercase text-secondary text-xxs font-weight-bolder opacity-7">Id</th>
                                                    <th className="text-uppercase text-secondary text-xxs font-weight-bolder opacity-7">image</th>
                                                    <th className="text-uppercase text-secondary text-xxs font-weight-bolder opacity-7">name</th>
                                                    <th className="text-uppercase text-secondary text-xxs font-weight-bolder opacity-7">category</th>
                                                    <th className="text-uppercase text-secondary text-xxs font-weight-bolder opacity-7">Action</th>
                                                </tr>
                                            </thead>
                                            <tbody>
                                                {products.map((product, index) => (
                                                    <tr key={index} className='align-items-center'>
                                                        {/* {headers.map(header => (
                                                            <td key={header} className="align-middle text-center text-sm">
                                                                {header === 'image' && product[header] ? (
                                                                    <img src={product[header]} alt={product.name} style={{ width: '100px', height: 'auto' }} />
                                                                ) : (
                                                                    product[header]
                                                                )}
                                                            </td>
                                                        ))} */}
                                                        <td>
                                                            <p style={{ margin: "27%" }}>{product.id}</p>
                                                        </td>
                                                        <td>
                                                            <img src={product.image[0]} alt={product.name} style={{ width: '100px', height: 'auto' }} />
                                                        </td>
                                                        <td>
                                                            <p>{product.name}</p>
                                                        </td>
                                                        <td>
                                                            <p>{product.category_name}</p>
                                                        </td>
                                                        <td className="">
                                                            <button className="btn btn-primary" onClick={() => handleViewDetail(product.id)}>View Detail</button>
                                                        </td>
                                                    </tr>
                                                ))}
                                            </tbody>

                                        </table>
                                    </div>
                                    <button className="btn btn-secondary m-2" onClick={handleCurrentPage}>Next page</button>
                                    <button className="btn btn-secondary m-2" onClick={handlePreviousPage} disabled={currentPage <= 1}>Previous page</button>
                                    {/* <button onClick={handleNextPage} disabled={currentPage >= totalPages}>Next page</button> */}

                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </main>
        </>
    )
}

export default Home