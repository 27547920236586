class Helpers {
  static appName = "Excel Sheet";
  static localhost = "127.0.0.1:8000";
  static server = "https://excelapi.cyberifyportfolio.com";
  static basePath = `${this.server}`;
  //   static basePath = `//${this.localhost}`;
  static apiUrl = `${this.basePath}/api/`;
}

export default Helpers;
