import React from 'react';

function SpecificationsCard({ specifications }) {
    // Only proceed if specifications is an object
    if (typeof specifications !== 'object' || specifications === null) {
        // Optionally, render nothing or a placeholder
        return null; // or <div>Loading...</div> for a loading state, for example
    }

    return (
        <div className="row">
            {Object.entries(specifications).map(([groupName, specs], index) => (
                <div key={index} className="col-md-4 mb-3">
                    <div className="card">
                        <div className="card-header">
                            <h5>{groupName}</h5>
                        </div>
                        <div className="card-body">
                            <ul>
                                {Object.entries(specs).map(([specName, specValue], specIndex) => (
                                    <li key={specIndex}>
                                        <strong>{specName}:</strong> {specValue}
                                    </li>
                                ))}
                            </ul>
                        </div>
                    </div>
                </div>
            ))}
        </div>
    );
}


export default SpecificationsCard;
