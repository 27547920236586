import React, { useState, useEffect } from 'react';
import axios from 'axios';
import Helpers from './Helpers';

export default function OpenSetting() {
    const [isLoading, setIsLoading] = useState(false);
    const [openAiKey, setOpenAiKey] = useState('');
    useEffect(() => {
        const key = localStorage.getItem('openAi_key');
        setOpenAiKey(key || ''); // Set the initial state to the value from localStorage or an empty string if it's not available
    }, []);
    const handleInputChange = (e) => {
        setOpenAiKey(e.target.value);
    }

    const handleSetOpenAiKey = async (e) => {
        setIsLoading(true);
        e.preventDefault();
        const apiUrl = Helpers.apiUrl;
        try {
            const response = await axios.post(apiUrl + 'settings', { open_ai_key: openAiKey });
            console.log(response.data.message);
            localStorage.setItem('openAi_key', response.data.setting.open_ai_key);
            setOpenAiKey(response.data.setting.open_ai_key);
            const key = localStorage.getItem('openAi_key'); // Use getItem instead of get
            console.log(key);
            setIsLoading(false);
        } catch (error) {
            console.error(error.response ? error.response.data.message : error.message); // Check if error.response exists
        }


    }

    return (
        <div>
            <div className="col-md-12">
                <form onSubmit={handleSetOpenAiKey}>
                    <div className="row">
                        <div className="col-md-12 p-5">
                            <div className="form-group">
                                <label htmlFor="openai_key" className="label-control">Open AI Key</label>
                                <input type="text" className='form-control' name="openai_key" value={openAiKey} onChange={handleInputChange} placeholder='Update OpenAI key...' />
                            </div>
                            <div className="form-group">
                                <button type="submit" className='btn btn-success float-right'>
                                    {isLoading ? 'Loading...' : 'Update OpenAI'}
                                </button>
                            </div>
                        </div>
                    </div>
                </form>
            </div>
        </div>
    );
}
