import React, { useEffect, useState } from 'react';
import axios from 'axios';
import Sidebar from './Sidebar';
import { useParams } from 'react-router-dom';
import Helpers from './Helpers';
import SpecificationsCard from './SpecificationsCard';
const ProductDetail = () => {
    const [productDetail, setProductDetail] = useState({});
    const { id } = useParams();
    let specifications = {};
    try {
        // Parse specifications into an object if it's a string
        specifications = typeof productDetail.specification === 'string'
            ? JSON.parse(productDetail.specification)
            : productDetail.specification;
    } catch (error) {
        console.error("Error parsing specifications:", error);
        specifications = {}; // Fallback to an empty object in case of error
    }


    useEffect(() => {
        axios.get(`${Helpers.apiUrl}get-product-detail/${id}`)
            .then(response => {
                setProductDetail(response.data);
                console.log(response.data);
            })
            .catch(error => {
                console.error('Error fetching product detail:', error);
            });
    }, [id]);
    let keyPoints = [];
    try {
        keyPoints = JSON.parse(productDetail.key_points);
    } catch (error) {
        console.error('Failed to parse key points JSON:', error);
        // Optionally, handle the error or set a fallback for keyPoints
    }
    // Assuming productDetail.image is a JSON-encoded string of image URLs
    const images = JSON.parse(productDetail.image || '[]');

    return (
        <>
            {/* <h1>Hello</h1> */}
            {/* <Sidebar /> */}
            <main className="main-content position-relative max-height-vh-100 h-100 mt-1 border-radius-lg ">
                <nav className="navbar navbar-main navbar-expand-lg px-0 mx-4 shadow-none border-radius-xl" id="navbarBlur" navbar-scroll="true">
                    <div className="container-fluid py-1 px-3">
                        <nav aria-label="breadcrumb">
                            <ol className="breadcrumb bg-transparent mb-0 pb-0 pt-1 px-0 me-sm-6 me-5">
                                <li className="breadcrumb-item text-sm"><a className="opacity-5 text-dark" href="#">Product Detail Page</a></li>
                                <li className="breadcrumb-item text-sm text-dark active" aria-current="page">Detail Page will be here</li>
                            </ol>

                        </nav>
                        <div className="collapse navbar-collapse mt-sm-0 mt-2 me-md-0 me-sm-4" id="navbar">
                            <div className="ms-md-auto pe-md-3 d-flex align-items-center">
                                <div className="input-group">
                                    <span className="input-group-text text-body"><i className="fas fa-search" aria-hidden="true"></i></span>
                                    <input type="text" className="form-control" placeholder="Type here..." />
                                </div>
                            </div>
                            <ul className="navbar-nav  justify-content-end">

                            </ul>
                        </div>
                    </div>
                </nav>
                <div className="container-fluid py-4">
                    <div className="row">
                        <div className="col-12">
                            <div className="card mb-4">
                                <div className="card-header pb-0">
                                    {/* <h6>Upload Data</h6> */}
                                </div>
                                <div className="card-body px-0 py-2 pt-0 pb-2">
                                    <div className="row">
                                        <div className="col-md-6">
                                            {/* Display the first image in larger size */}
                                            {images.length > 0 && (
                                                <img src={images[0]} alt="Main Product" className="img-fluid" />
                                            )}
                                        </div>
                                        <div className="col-md-6 p-5">
                                            <h3 className="mb-3">{productDetail.name}</h3>
                                            <h6>{productDetail['stock-status']}</h6>

                                            
                                            {/* <h3>Key Points</h3> */}
                                            {keyPoints.length > 0 ? (
                                                <ul>
                                                    {keyPoints.map((point, index) => (
                                                        <li key={index}>{point}</li>
                                                    ))}
                                                </ul>
                                            ) : (
                                                <p>No key points available.</p>
                                            )}

                                            {/* Optionally display additional product details here */}
                                            <label htmlFor="">Description :</label>
                                            <p className="mb-3">{productDetail.description}</p>
                                            <h6>{productDetail.article_number}</h6>
                                        </div>
                                    </div>
                                    <div className="row mt-2">
                                        {/* Display the rest of the images in smaller size */}
                                        {images.slice(1).map((image, index) => (
                                            <div key={index} className="col-2 col-md-1 mt-2">
                                                <img src={image} alt={`Product ${index + 1}`} className="img-fluid" />
                                            </div>
                                        ))}
                                    </div>
                                    <div className="row">
                                        <div className="col-md-12 p-5">
                                            <label htmlFor="">Specification :</label>
                                            <SpecificationsCard specifications={specifications} />
                                        </div>
                                    </div>
                                </div>


                            </div>
                        </div>
                    </div>
                </div>
            </main>
            {/* <div className="container mt-5">
                <div className="row">
                    <div className="col-md-6">
                        <img src={productDetail.image} alt={productDetail.name} className="img-fluid" />
                    </div>
                    <div className="col-md-6">
                        <h2 className="mb-3">{productDetail.name}</h2>
                    </div>
                </div>
            </div> */}
        </>
    );
};

export default ProductDetail;
