
import Sidebar from './Sidebar'
import React, { useRef, useState, useEffect } from 'react';
import axios from 'axios';
import toast from 'react-hot-toast';
import Helpers from './Helpers';
function Dashboard() {
    const fileOneRef = useRef(null);
    const fileTwoRef = useRef(null);
    const [modalData, setModalData] = useState(null); // State to store modal data
    const [headersData, setHeadersData] = useState(null);
    const [products, setProducts] = useState([]);
    const [totalProducts, setTotalProducts] = useState(0);
    const [processedProducts, setProcessedProducts] = useState(0);
    const [uploadProgress, setUploadProgress] = useState(0);
    const [isLoading, setIsLoading] = useState(false)
    const [isComplete, setIsComplete] = useState(false);
    const categoryNameRef = useRef(null);
    const [isValue, setInputValue] = useState('')
    const handleInputChange = (event) => {
        const currentValue = event.target.value;
        setInputValue(currentValue); // Update state
        console.log(currentValue); // Log current value to console
    };
    useEffect(() => {
        if (totalProducts > 0) {
            setUploadProgress((processedProducts / totalProducts) * 100);
        }
    }, [processedProducts, totalProducts]);
    const handleUpload = async () => {
        // console.log("Input Value",isValue);
        const formData = new FormData();
        if (fileOneRef.current.files[0]) {
            formData.append('first_excel_file', fileOneRef.current.files[0]);
        }
        if (fileTwoRef.current.files[0]) {
            formData.append('second_excel_file', fileTwoRef.current.files[0]);
        }
        formData.append('category_name', isValue);
        console.log(isValue)
        setIsLoading(true)
        try {
            const uploadResponse = await axios.post(`${Helpers.apiUrl}sheetpost`, formData, {
                headers: { 'Content-Type': 'multipart/form-data' },
            });
            const urls = uploadResponse.data.urls;
            const heads = uploadResponse.data.headers;// Adjust based on the actual response structure
            setHeadersData(uploadResponse.data.headers);
            console.log(formData.isValue)
            console.log(uploadResponse.data);
            setTotalProducts(urls.length)
            setProcessedProducts(0);
            const cat_id = uploadResponse.data.cat;



            for (const url of urls) {
                const dataToSend = {
                    url: url, // This is correct
                    category: cat_id,
                    heads: heads // Ensure this matches how you've stored the category_name value
                };
                console.log(Helpers.apiUrl, url, cat_id, heads);

                try {
                    const processResponse = await axios.post(`${Helpers.apiUrl}processing`, dataToSend, {
                        headers: { 'Content-Type': 'application/json' },
                    });

                    console.log(processResponse.data.message);
                    console.log('modal', processResponse.data.modal);

                    // Accumulate messages safely
                    setProducts(prevMessages => [...prevMessages, processResponse.data.message]);

                    // Set modal data once if it's not null and has not been set before
                    if (processResponse.data.modal !== null && modalData === null) {
                        setModalData(processResponse.data.modal);
                    }

                    // Increment processed products count safely
                    setProcessedProducts(prevCount => prevCount + 1);

                } catch (error) {
                    console.error('Error processing URL:', url, error.response ? error.response.data : error.message);
                    // Handle specific API or network errors appropriately
                }
            }

            setIsLoading(false)
            setIsComplete(true)
        } catch (error) {
            setIsLoading(false)
            console.error('Error in processing', error);
            toast.error(error.message);
        }
    };


    const handleDownload = async () => {
        const sendData = {
            cat: isValue,
            headers: headersData,
            modal: modalData,
            products: products

        }
        console.log(products);
        const responsive = await axios.post(`${Helpers.apiUrl}get-excel`, sendData, {
            headers: { 'Content-Type': 'application/json' },
        });
        if (responsive.status === 200) {
            const downloadLink = responsive.data.downloadLink;
            console.log("Download Link:", downloadLink);


            if (downloadLink) {
                window.location.href = downloadLink;

            }
        }
    }



    // const fetchTotalProducts = () => {
    //     axios.post('http://127.0.0.1:8000/api/getno', { /* formData might not be needed here if you're just fetching the total count */ }, {
    //         headers: {
    //             'Content-Type': 'application/json',
    //         },
    //     })
    //         .then(response => {
    //             const total = response.data.total_products; // Assuming response structure. Adjust according to actual response
    //             setTotalProducts(total);
    //             pollProcessingStatus(); // Start polling to check processing status
    //             total.forEach(single => {
    //                 await axios.post('')
    //             });
    //         })
    //         .catch(error => {
    //             console.error('Error fetching total number of products', error);
    //         });
    // };

    const pollProcessingStatus = () => {
        const interval = setInterval(() => {
            axios.get('http://127.0.0.1:8000/api/processing-status')
                .then(response => {
                    const { processed_products } = response.data;
                    setProcessedProducts(processed_products);
                    setUploadProgress((processed_products / totalProducts) * 100);

                    if (processed_products >= totalProducts) {
                        clearInterval(interval);
                        console.log('Processing complete');
                    }
                })
                .catch(error => {
                    console.error('Error fetching processing status', error);
                    clearInterval(interval);
                });
        }, 2000);
    };


    return (
        <>
            <Sidebar />
            <main className="main-content position-relative max-height-vh-100 h-100 mt-1 border-radius-lg ">
                <nav className="navbar navbar-main navbar-expand-lg px-0 mx-4 shadow-none border-radius-xl" id="navbarBlur" navbar-scroll="true">
                    <div className="container-fluid py-1 px-3">
                        <nav aria-label="breadcrumb">
                            <ol className="breadcrumb bg-transparent mb-0 pb-0 pt-1 px-0 me-sm-6 me-5">
                                <li className="breadcrumb-item text-sm"><a className="opacity-5 text-dark" href="#">Add Excel Data</a></li>
                                <li className="breadcrumb-item text-sm text-dark active" aria-current="page">Add Data</li>
                            </ol>

                        </nav>
                        <div className="collapse navbar-collapse mt-sm-0 mt-2 me-md-0 me-sm-4" id="navbar">
                            <div className="ms-md-auto pe-md-3 d-flex align-items-center">
                                <div className="input-group">
                                    <span className="input-group-text text-body"><i className="fas fa-search" aria-hidden="true"></i></span>
                                    <input type="text" className="form-control" placeholder="Type here..." />
                                </div>
                            </div>
                            <ul className="navbar-nav  justify-content-end">

                            </ul>
                        </div>
                    </div>
                </nav>
                <div className="container-fluid py-4">
                    <div className="row">
                        <div className="col-12">
                            <div className="card mb-4">
                                <div className="card-header pb-0">
                                    <h6>Upload Data</h6>
                                </div>
                                <div className="card-body px-0 py-2 pt-0 pb-2">
                                    <div className="m-3">
                                        <label className="form-label">Enter Category</label>
                                        <input
                                            type="text"
                                            className="form-control"
                                            placeholder='Enter a category'
                                            name='category_name'
                                            onChange={handleInputChange} />
                                    </div>
                                    <div className="m-3">
                                        <label htmlFor="fileOne" className="form-label">Upload Data File</label>
                                        <input type="file" className="form-control" id="fileOne" ref={fileOneRef} />
                                    </div>
                                    <div className="m-3">
                                        <label htmlFor="fileTwo" className="form-label">Upload Format File</label>
                                        <input type="file" className="form-control" id="fileTwo" ref={fileTwoRef} />
                                    </div>
                                    <button onClick={handleUpload} className="btn btn-primary m-3">{isLoading ? 'Loading ..' : 'Upload'}</button>
                                    {isComplete &&
                                        <button onClick={handleDownload} className="btn btn-success m-3">Download Sheet</button>
                                    }
                                </div>
                                <div className="m-3">
                                    <p>Products to be processed {totalProducts}</p>
                                    <progress value={uploadProgress} max="100"></progress>
                                    {uploadProgress > 0 && (
                                        <p>Processing is {uploadProgress.toFixed(2)}% complete ({processedProducts}/{totalProducts} products processed)</p>
                                    )}
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </main>
        </>
    )
}

export default Dashboard