import React, { useState } from 'react';
import axios from 'axios';
import Helpers from './Helpers';

export default function ResetPassword() {
    const [isLoading, setIsLoading] = useState(false);
    const [formData, setFormData] = useState({
        current_password: '',
        new_password: '',

    });

    const handleInputChange = (e) => {
        setFormData({ ...formData, [e.target.name]: e.target.value });
    }

    const handleResetPassword = async (e) => {
        setIsLoading(true);
        e.preventDefault();
        const token = localStorage.getItem('token');
        console.log(token);
        const apiUrl = Helpers.apiUrl;
        try {
            const response = await axios.post(apiUrl + 'reset-password', formData, {
                headers: {
                    'Authorization': `Bearer ${token}`,
                    'Accept': 'Appllication/json'
                }
            });
            console.log(response.data.message); // Handle success message
            setIsLoading(false);
            setFormData({
                current_password: '',
                new_password: '',
            });
        } catch (error) {
            console.error(error.response.data.message); // Handle error message
        }
    }

    return (
        <div>
            <div className="col-md-12 my-5">
                <h2 className='px-3'>Reset Password</h2>
                <form onSubmit={handleResetPassword}>
                    <div className="row">
                        <div className="col-md-12 p-5">
                            <div className="form-group">
                                <label htmlFor="current_password" className="label-control">Current Password</label>
                                <input type="password" className='form-control' name="current_password" value={formData.current_password} onChange={handleInputChange} placeholder='Enter your current password...' />
                            </div>
                            <div className="form-group">
                                <label htmlFor="new_password" className="label-control">New Password</label>
                                <input type="password" className='form-control' name="new_password" value={formData.new_password} onChange={handleInputChange} placeholder='Enter your new password...' />
                            </div>

                            <div className="form-group">
                                <button type="submit" className='btn btn-success float-right'>
                                    {isLoading ? 'Loading...' : 'Reset Password'}
                                </button>

                            </div>
                        </div>
                    </div>
                </form>
            </div>
        </div>
    );
}
