import logo from "./logo.svg";
import "./App.css";
import Sidebar from "./App/Components/Sidebar";
import Home from "./App/Components/Home";
import Homepage from "./Pages/Homepage";
import { createBrowserRouter, RouterProvider } from "react-router-dom";
import Dashboard from "./App/Components/Dashboard";
import ProductDetail from "./App/Components/ProductDetail";
import DetailPage from "./Pages/DetailPage";
import { Toaster } from "react-hot-toast";
import Register from "./App/Components/Register";
import Login from "./App/Components/Login";
import { Navigate } from "react-router-dom";
import { Outlet } from "react-router-dom";
import Setting from "./App/Components/Setting";
const isAuthenticated = () => !!localStorage.getItem("token");

const ProtectedLayout = () => {
  return isAuthenticated() ? <Outlet /> : <Navigate to="/login" />;
};

const router = createBrowserRouter([
  {
    path: "/",
    element: <ProtectedLayout />,
    children: [
      { path: "/", element: <Dashboard /> },
      { path: "dashboard", element: <Dashboard /> },
      { path: "link", element: <Home /> },
      { path: "path-to-detail-page/:id", element: <DetailPage /> },
      { path: "setting", element: <Setting /> },
      // Add other protected routes as children of ProtectedLayout
    ],
  },
  {
    path: "login",
    element: <Login />,
  },
  {
    path: "register",
    element: <Register />,
  },
  // Define other public routes here
]);
function App() {
  // const router = createBrowserRouter([
  //   {
  //     path: "/",
  //     element: <Dashboard />,

  //   },
  //   {
  //     path: "dashboard",
  //     element: <Dashboard />

  //   },
  //   {
  //     path: "link",
  //     element: <Home />,

  //   },
  //   {
  //     path:"path-to-detail-page/:id",
  //     element: <DetailPage />,
  //   },
  //   {
  //     path:"register",
  //     element: <Register />,
  //   },
  //   {
  //     path:"login",
  //     element: <Login />,
  //   },
  // ]);
  return (
    <>
      <Toaster />

      <RouterProvider router={router} />
    </>
  );
}

export default App;
